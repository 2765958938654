import React from "react"
import { useStaticQuery } from "gatsby"
import moment from 'moment'
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/Home/Hero/Hero"
import AboutUs from "../components/Home/AboutUs/AboutUs"
import UpComingEvents from "../components/Home/UpComingEvents/UpComingEvents"
import EventsPhotos from "../components/Home/EventPhotos/EventPhotos"
import HomeResearch from "../components/Home/HomeResearch/HomeResearch"
import Consortia from "../components/Home/Consortia/Consortia"
import Stories from "../components/Home/Stories/Stories"
import Team from "../components/Home/Team/Team"
import Press from "../components/Home/Press/Press"

const IndexPage = () => {
  const {
    allWordpressWpUpcomingEvents: { edges },
  } = useStaticQuery(graphql`
    query HomeUpcomingEvent {
      allWordpressWpUpcomingEvents {
        edges {
          node {
            acf {
              event_information {
                date
                time
                event_description
                event_title
                events_agenda
                is_registration_open
                location
                show_event_in_menu
              }
              previous_event_section {
                photos
                section_title
              }
            }
          }
        }
      }
    }
  `)
  const sortedDates = edges
    .slice()
    .sort(
      (a, b) =>
        parseInt(moment(b.node.acf.event_information.date).format("x")) -
        parseInt(moment(a.node.acf.event_information.date).format("x"))
    )
  
  const lastEdge = sortedDates[0]
  return (
    <Layout>
      <SEO title="Home" />
      <Hero eventInfo={lastEdge.node.acf.event_information} />
      <AboutUs />

      {(lastEdge.node.acf.event_information || {}).is_registration_open && (
        <UpComingEvents eventInfo={lastEdge.node.acf.event_information} />
      )}

      {lastEdge.node.acf.previous_event_section && (
        <EventsPhotos
          UpcomingPastEventPhotos={lastEdge.node.acf.previous_event_section}
        />
      )}

      <HomeResearch />
      <Consortia />
      {/* <Stories /> */}
      <Team />
      <Press />
    </Layout>
  )
}

export default IndexPage
