import styled from "styled-components"
import { LG, BREAKPOINT_CEL_ONLY, XL } from "../../../../utils/constants"
import CloseX from "../../../../images/close-video-modal.svg"

export const Wrapper = styled.div`
  margin-top: 40px;
  overflow: hidden;

  @media (max-width: ${LG}) {
    margin-bottom: 20px;
  }

  .slick-slide {
    opacity: 0.6;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-slider {
    padding: 100px 0 50px;

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: ${LG}) {
      padding: 100px 0 60px;
    }
  }
  .slick-slide {
    cursor: pointer;

    > div {
      margin: 0 15px 0 15px;
      position: relative;

      @media (max-width: ${LG}) {
        margin: 0 3px 0 3px;
      }
    }

    @media (max-width: ${LG}) {
      margin: 0;
      /*   margin: 0 2.5px 0 2.5px; */
    }
  }

  .slick-track,
  .slick-list {
    height: 350px !important;
    padding: 50px 0;
    position: relative;
    top: -20px;
  }

  .slick-next {
    top: 0;
    right: unset;
    left: 88%;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translate(0);
    z-index: 10;

    &:before {
      display: none;
    }

    @media (max-width: ${XL}) {
      left: 82%;
      height: 50%;
      transform: translate(0, 39%);
    }
  }
  .slick-prev {
    top: 0;
    right: 80%;
    left: 0;
    bottom: 0;
    width: 12%;
    height: 100%;
    transform: translate(0);
    z-index: 10;

    @media (max-width: ${XL}) {
      width: 18%;
      height: 50%;
      transform: translate(0, 39%);
    }

    &:before {
      display: none;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @media (max-width: ${LG}) {
    .slick-dots {
      bottom: 115px;
      li {
        background-color: red;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        vertical-align: middle;
        transition: ${props => props.theme.defaultTransition()};
        background-color: #b0c9cb;

        &.slick-active {
          width: 12px;
          height: 12px;
          background: linear-gradient(97.08deg, #f38b97 20.01%, #f4904d 75.82%);
        }

        button {
          width: 12px;
          height: 12px;
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .rodal-mask {
    position: absolute;
    background: #faf7f5;
    opacity: 0.8;
  }

  .rodal-dialog {
    width: 80% !important;
  }

  .rodal-close {
    background-color: white;
    box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
    width: 26px;
    height: 21px;
    padding: 36px;
    opacity: 1;
    border-radius: 50%;
    background: url(${CloseX}) no-repeat center center;
    background-color: white;
    top: -50px;
    right: -70px;
    transition: ${props => props.theme.defaultTransition()};
    z-index: 999;

    &:hover {
      box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.3);
    }

    &:after,
    &:before {
      display: none;
    }

    @media (max-width: ${LG}) {
      top: 10%;
      right: -5%;
      padding: 30px;
    }

    @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
      top: 30%;
      right: -7vw;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export const PlayIconWrap = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 50%;
  margin-left: -32px;
  top: 45px;
  z-index: 20;

  transition: ${props => props.theme.defaultTransition()};

  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 40px;
    height: 40px;
    position: relative;
    top:5px;
    left:1px;
  }

  img {
    width: 30% !important;
    margin: 0 auto;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

export const MaskHover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${props => props.theme.orangeGradient};

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  transition: ${props => props.theme.defaultTransition()};
  visibility: hidden;
  opacity: 0;
  border-radius: 7px;
  border-radius: 7px;
  bottom:20px;
`

export const Card = styled.div`
  border-radius: 9px;
  box-shadow: 2px 6px 48px rgba(243, 139, 151, 0.27);
  background-color: white;
  background: url(${props => props.bg}) no-repeat center center / cover;
  position: relative;
  transition: ${props => props.theme.defaultTransition()};

  > div {
    width: 100%;
    height: 200px;
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.theme.pink};
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.neutralDark};
    margin-top: 5px;
  }

  > div {
    width: 100%;
    height: 230px;
  }

  &:hover {
    ${MaskHover} {
      opacity: 0.8;
      visibility: visible;
    }

    ${PlayIconWrap} {
      transform: scale(1.2);
      background-color: white;

      svg {
        path {
          fill: #f4904d;
        }
      }
    }
  }
`

export const Preview = styled.div``

export const Video = styled.div``

export const TextContainer = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 10;
`
