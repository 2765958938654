import styled from "styled-components"

import { LG, XL } from "../../../../utils/constants"

import { MainTextLink, TextLinkWrap } from "../../../../utils/GlobalComponents"
import ArrowLeft from "../../../../images/arrow-left.png"
import ArrowRight from "../../../../images/arrow-right.png"

export const Wrapper = styled.div`
  .slick-slider {
    /* padding: 20px 0 100px; */
  }
  .slick-slide {
    cursor: pointer;
    margin: 0 0px 0px 30psx;
    opacity: 0.6;
  }

  .slick-next,
  .slick-prev {
    transform: translate(-50%, -50%);
  }

  .slick-next {
    width: 64px;
    height: 64px;
    z-index: 999;
    right: 60px;
  }

  .slick-active
    + .slick-active
    + .slick-active
    + .slick-active
    + .slick-active {
    opacity: 0.6;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-prev {
    width: 64px;
    height: 64px;
    z-index: 999;
    left: 120px;
    display: block;
    opacity: 1;
  }

  .slick-prev:before {
    content: "";
    width: 64px;
    height: 64px;
    background: url(${ArrowLeft}) no-repeat center center / contain;
    display: block;
    opacity: 1;
  }

  .slick-next:before {
    content: "";
    width: 64px;
    height: 64px;
    background: url(${ArrowRight}) no-repeat center center / contain;
    display: block;
    opacity: 1;
  }

  @media (max-width: ${XL}) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }

  @media (max-width: ${LG}) {
    .slick-dots {
      bottom: -60px;
      li {
        background-color: red;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        vertical-align: middle;
        transition: ${props => props.theme.defaultTransition()};
        background-color: white;
        opacity: 0.8;

        &.slick-active {
          width: 12px;
          height: 12px;
          background: white;
          opacity: 1;
        }

        button {
          width: 12px;
          height: 12px;
          &:before {
            display: none;
          }
        }
      }
    }
  }
`

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
  border-radius: 8px;
  padding: 10px 10px 30px 10px;
  margin-right: 10px;
  margin-left: 10px;

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.theme.neutralDark};
    opacity: 0.6;
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.theme.neutralGreen};
  }
`

export const TextLink = styled(MainTextLink)``

export const LinkWrap = styled(TextLinkWrap)`
  margin-left: 20px;
`

export const PressMedia = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
`

export const CardTitle = styled.h4`
  max-width: 231px;
  height: 200px;
  overflow: hidden;
  font-style: normal !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: none !important;
  padding-left: 20px;
  padding-top: 20px;

  letter-spacing: 0.01em;
  opacity: 1 !important;

  color: ${props => props.theme.navy};
`
