import styled from "styled-components"

import { LG } from "../../../utils/constants"

import {
  SupTitle,
  MainH3,
  MainParagraph,
  PrimaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  text-align: center;
  overflow: hidden;
  position: relative;
  top: 90px;
`

export const TopTitle = styled(SupTitle)`
  &:after {
    display: none;
  }
`

export const Title = styled(MainH3)`
  background: ${props =>
    props.gradient ? props.gradient : props.theme.orangeGradient};
  -webkit-background-clip: text;
  font-weight: 500;
  -webkit-text-fill-color: transparent;
  margin-top: 5px;
  margin-bottom: 10px;

  @media (max-width: ${LG}) {
    max-width: 304px;
    margin: 0 auto;
    padding-top: 3px;
    padding-bottom: 18px;
  }
`

export const Text = styled(MainParagraph)`
  max-width: 713px;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 304px;
  }
`

export const StoriesButton = styled(PrimaryBtn)`
  display: inline-block;
  span {
    line-height: 1.8;
  }
`

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: -60px;
  position: relative;
  top: -50px;
  padding: 0 30px;
`
