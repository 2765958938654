import React, { useState } from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { useMediaQuery } from "react-responsive"
import moment from 'moment'
import {
  Wrapper,
  TitleContainer,
  TextContainer,
  Title,
  Paragraph,
  TopTitle,
  Grid,
  EventContainer,
  ButtonsContainer,
  ViewEventBtn,
  RegisterBtn,
} from "./style"

import { PrimaryBtnExternal } from "../../../utils/GlobalComponents"

import RegisterModal from "../../RegisterModal/RegisterModal"
import CalendarIcon from "../../../images/lyme-local-images/Calendar-48x48.png"
import PinIcon from "../../../images/lyme-local-images/Pin-48x48.png"

const UpComingEvents = ({ eventInfo }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })
  return (
    <Wrapper>
      <RegisterModal visible={modalOpen} onClose={() => handleModalOpen()} />

      <DefaultWrapper>
        <Grid>
          <TitleContainer data-aos="fade-up" data-aos-duration="1000">
            <TopTitle>Upcoming Event</TopTitle>
            <Title
              dangerouslySetInnerHTML={{ __html: eventInfo.event_title }}
            />
          </TitleContainer>
          <TextContainer data-aos="fade-up" data-aos-duration="1000">
            <Paragraph
              dangerouslySetInnerHTML={{ __html: eventInfo.event_description }}
            ></Paragraph>
          </TextContainer>
        </Grid>
        {isTabletOrMobileDevice && (
          <div>
            <ul>
              <li>
                <img src={CalendarIcon} alt={"Calendar"} />
                <span>{eventInfo.date} {eventInfo.time}</span>
              </li>
              <li>
                <img src={PinIcon} alt={"Pin"} />
                <div className="event__place">
                  <span>{eventInfo.location}</span>
                  {/*                   <em> {edges[0].node.acf.home_upcoming_event_capacity}</em> */}
                </div>
              </li>
            </ul>
          </div>
        )}
        <div></div>
        <EventContainer data-aos="fade-up" data-aos-duration="1000">
          {isDesktopOrLaptop && (
            <ul>
              <li>
                <img src={CalendarIcon} alt={"Calendar"} />

                <span>{eventInfo.date}, {eventInfo.time}</span>
              </li>
              <li>
                <img src={PinIcon} alt={"Pin"} />
                <div className="event__place">
                  <span>{eventInfo.location}</span>
                  {/*   <em> {edges[0].node.acf.home_upcoming_event_capacity}</em> */}
                </div>
              </li>
            </ul>
          )}

          <ButtonsContainer data-aos="fade-up" data-aos-duration="1000">
            {/* <ViewEventBtn to="/past-event-2020" secondary={"A"}>
              <span>View Event Details</span>
            </ViewEventBtn> */}
              <PrimaryBtnExternal
                // onClick={() => handleModalOpen(true)}
                href="https://lyme-mind-2022.open-exchange.net/welcome"
                target="_blank" rel="noopener noreferrer"
                primary={"A"}
              >
                <span>Register Now</span>
              </PrimaryBtnExternal>
          </ButtonsContainer>
        </EventContainer>
      </DefaultWrapper>
    </Wrapper>
  )
}

export default UpComingEvents
