import styled from "styled-components"
import { BREAKPOINT_CEL_ONLY, LG, XL } from "../../../utils/constants"

import {
  MainH3,
  MainParagraph,
  PrimaryBtn,
  SecondaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  background: url(${props => props.bg}) no-repeat center center / cover;
  margin-top: 100px;
  margin-bottom: 150px;

  @media (max-width: ${XL}) {
    padding: 0 30px;
  }

  @media (max-width: ${LG}) {
    background: url(${props => props.bgMobile}) no-repeat center center / cover;
    height: 340px;
    margin-bottom: 200px;
    margin-top: 60px;
  }

  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    margin-bottom: 400px;
  }

  ul {
    display: flex;

    @media (max-width: ${LG}) {
      flex-direction: column;
      padding-left: 40px;
      padding-bottom: 10px;
    }
    li,
    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    li {
      margin-right: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: ${props => props.theme.neutralDark};

      @media (max-width: ${LG}) {
        margin-bottom: 15px;
      }
    }
  }
`

export const CardWrapper = styled.div`
  position: relative;
  top: 122px;
  box-shadow: 1px 28px 30px rgba(0, 43, 52, 0.05);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (max-width: ${LG}) {
    top: 60px;
  }
`

export const Card = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  padding: 50px 50px 50px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  @media (max-width: ${LG}) {
    background-color: white;
    padding: 50px 50px 20px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 280px;
      width: 100%;
      background-color: ${props => props.theme.neutralSilver};
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
`

export const Title = styled(MainH3)`
  font-weight: 500;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
`

export const Text = styled(MainParagraph)`
  max-width: 606px;
  position: relative;
  z-index: 1;
`

export const BottomCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  padding: 30px 25px 30px 50px;
  position: relative;
  top: -1px;
  align-items: center;

  @media (max-width: ${LG}) {
    padding: 30px 0 30px 0px;
  }

  > div {
    display: flex;
    justify-content: flex-end;

    @media (max-width: ${LG}) {
      flex-direction: column;
      padding: 0 30px;
    }
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`

export const ConsortiaBtn = styled(SecondaryBtn)`
  margin-right: 30px;

`

export const ViewEventBtn = styled(PrimaryBtn)`
  margin-left: 20px;

  @media (max-width: ${LG}) {
    margin-top: 15px;
  }
`
