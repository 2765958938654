import React, { Fragment } from "react"
import { useStaticQuery } from "gatsby"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { Grid, TopTitle, Title, Paragraph, ButtonVisit, Card } from "./style"

const HomeResearch = () => (
  <DefaultWrapper>
    <Fragment>
      <TopTitle>The Research</TopTitle>
      <Grid>
        <Card data-aos="fade-up" data-aos-duration="1000">
          <Title
            gradient={
              "linear-gradient(90deg, #0F7F90 -8.75%, #00B08A 113.12%)"
            }
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            LymeMIND Commons
              </Title>
          <Paragraph data-aos="fade-up" data-aos-duration="1000">
            The LymeMIND team is aggregating information about the data and metadata that is collected by the Cohen Lyme & Tickborne Disease Initiative by providing this data and metadata for search and download.
              </Paragraph>
          <ButtonVisit
            data-aos="fade-up"
            data-aos-duration="1000"
            to="https://commons.lymemind.org/#/MetadataSearch"
            secondary={"A"}
            as="a"
            target="_blank"
            href="https://commons.lymemind.org/#/MetadataSearch"
          >
            <span>Visit Site</span>
          </ButtonVisit>
        </Card>
        <Card
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <Title data-aos="fade-up" data-aos-duration="1000">
            Research Projects
              </Title>
          <Paragraph data-aos="fade-up" data-aos-duration="1000">
            The LymeMIND team is conducting several research projects to advance our understanding of Lyme Disease mechanisms toward the development of novel therapeutics and better diagnostics.
              </Paragraph>
          <ButtonVisit
            data-aos="fade-up"
            data-aos-duration="1000"
            to="research-projects"
            secondary={"B"}
          >
            <span>View Projects</span>
          </ButtonVisit>
        </Card>
      </Grid>
    </Fragment>
  </DefaultWrapper>
)

export default HomeResearch
