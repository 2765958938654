import styled from "styled-components"
import { LG, XL } from "../../../utils/constants"

import {
  MainH2,
  MainParagraph,
  SupTitle,
  MainTextLink,
  PrimaryBtn,
  SecondaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  padding: 170px 0;
  margin-top: -40px;

  @media (max-width: ${XL}) {
    padding: 170px 30px;
    margin-top: -40px;
  }

  @media (max-width: ${LG}) {
    padding: 40px 30px;
    margin-top: -40px;

    > div {
      ul {
        display: grid;
        align-items: center;
        background-color: ${props => props.theme.neutralSilver};
        padding: 30px 50px 30px;
        margin-top: 15px;
        margin-bottom: 20px;
        border-radius: 8px;
      }
    }
  }

  ul {
    display: grid;
    grid-gap: 15px 0;

    @media (max-width: ${LG}) {
      padding-left: 50px;
    }

    li,
    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .event__place {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }
    }
    li {
      margin-right: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: ${props => props.theme.neutralDark};

      em {
        font-size: 16px;
        line-height: 24px;
        color: #b0c9cb;
        font-style: normal;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        @media (min-width: 768px) {
          margin-left: 8px;
        }
      }

      @media (max-width: ${LG}) {
        margin-bottom: 0;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }

    @media (max-width: ${LG}) {
      flex-direction: column;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /*   align-items: center; */

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`
export const TitleContainer = styled.div`
  justify-self: end;
  padding-right: 10px;

  @media (max-width: ${LG}) {
    justify-self: inherit;
  }
`

export const TextContainer = styled.div`
  padding-left: 15px;
  margin-top: 30px;

  @media (max-width: ${LG}) {
    padding-left: 0;
    margin-top: 15px;
  }
`

export const Title = styled(MainH2)`
  margin-top: 16px;
  max-width: 480px;

  @media (max-width: ${LG}) {
    margin-top: 10px;
    line-height: normal;
    max-width: inherit;
  }
`

export const Paragraph = styled(MainParagraph)`
  margin-bottom: 15px;
  max-width: 480px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.navy};
  letter-spacing: 0.01em;

  @media (max-width: ${LG}) {
    max-width: unset;
  }
`
export const TopTitle = styled(SupTitle)``

export const TextLink = styled(MainTextLink)`
  margin-top: 15px;
`

export const EventContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: ${props => props.theme.neutralSilver};
  padding: 50px;
  margin-top: 80px;
  border-radius: 8px;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    margin-top: 20px;
    padding: 0;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${LG}) {
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
`

export const ViewEventBtn = styled(SecondaryBtn)`
  margin-right: 20px;

  @media (max-width: ${LG}) {
    margin-bottom: 10px;
  }
`

export const RegisterBtn = styled(PrimaryBtn)``
