import React, { Fragment } from "react"
import SliderPress from "./SliderPress/SliderPress"
import { Wrapper, TopTitle, Title, TextContainer } from "./style"

import BgImage from "../../../images/lyme-local-images/bgPress.jpg"


const Press = () => (
  <Fragment>
    <Wrapper
      bgColor={BgImage}
    >
      <TextContainer>
        <TopTitle data-aos="fade-up" data-aos-duration="1000">
          Press
            </TopTitle>
        <Title data-aos="fade-up" data-aos-duration="1000">
          LymeMIND In the News
            </Title>
      </TextContainer>

      <SliderPress />
    </Wrapper>
  </Fragment>
)

export default Press
