import React, { Fragment } from "react"
import isNode from 'detect-node'
import { useStaticQuery } from "gatsby"
import moment from "moment"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import {
  Wrapper,
  Card,
  Title,
  Text,
  ConsortiaBtn,
  ViewEventBtn,
  BottomCard,
  CardWrapper,
} from "./style"

import CalendarIcon from "../../../images/lyme-local-images/Calendar-48x48.png"
import PinIcon from "../../../images/lyme-local-images/Pin-48x48.png"
import BgTextures from "../../../images/lyme-local-images/consortium-gradient.jpg"

const Consortia = () => {
  const {
    allWordpressWpConsortiumEvents: { edges },
  } = useStaticQuery(graphql`
    query MeetingHomeQuery {
      allWordpressWpConsortiumEvents {
        edges {
          node {
            date
            title
            acf {
              event_date
              event_description
              event_title
              event_venue
              show_event
            }
          }
        }
      }
    }
  `)

  const sortedDates = edges
    .slice()
    .sort(
      (a, b) =>
        parseInt(moment(b.node.date).format("x")) +
        parseInt(moment(a.node.date).format("x"))
    )

  let lastEdge
  if (isNode || navigator.userAgent.search("Firefox") === -1) lastEdge = sortedDates[0]
  else lastEdge = sortedDates[sortedDates.length - 1]
  return (
    <Fragment>
      {lastEdge !== null && lastEdge.node.acf.show_event === "yes" && (
        <Wrapper
          bg={BgTextures}
          bgMobile={BgTextures}
          data-aos="fade"
          data-aos-duration="1000"
          className="consortia-home-wrapper"
        >
          <DefaultWrapper
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
            data-aos-anchor=".consortia-home-wrapper"
          >
            <CardWrapper>
              <Card>
                <Title
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="600"
                >
                  {lastEdge.node.title}
                </Title>
                <Text
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="600"
                  dangerouslySetInnerHTML={{
                    __html: lastEdge.node.acf.event_description,
                  }}
                />
              </Card>
              <BottomCard
                data-aos="fade"
                data-aos-duration="1000"
                data-aos-delay="1000"
              >
                <ul>
                  <li>
                    <img src={CalendarIcon} alt={"Calendar"} />

                    <span>{lastEdge.node.acf.event_date}</span>
                  </li>
                  <li>
                    <img src={PinIcon} alt={"Pin"} />

                    <span>{lastEdge.node.acf.event_venue}</span>
                  </li>
                </ul>
                <div>
                  <ConsortiaBtn to="/consortium" secondary={"A"}>
                    <span>View Consortium</span>
                  </ConsortiaBtn>
                </div>
              </BottomCard>
            </CardWrapper>
          </DefaultWrapper>
        </Wrapper>
      )}
    </Fragment>
  )
}

export default Consortia
