import React from "react"
import { DefaultWrapper, TextLinkWrap } from "../../../utils/GlobalComponents"
import {
  Wrapper,
  TitleContainer,
  TextContainer,
  Title,
  Paragraph,
  TopTitle,
  TextLink,
  Grid,
} from "./style"

import Blob from "../../common/Blob/Blob"

const AboutUs = () => (
  <Wrapper>
    <DefaultWrapper>
      <Blob
        toTop={"50px"}
        toLeft={"-60px"}
        customWidth={"8.6vw"}
        customHeight={"9.3vw"}
      />

      <Blob
        toTop={"85%"}
        toRight={"26%"}
        customWidth={"9.3vw"}
        customHeight={"10vw"}
      />

      <Blob
        toTop={"60%"}
        toRight={"14%"}
        customWidth={"26vw"}
        customHeight={"28vw"}
      />

      <Grid>
        <TitleContainer data-aos="fade-up" data-aos-duration="1000">
          <TopTitle>About Us</TopTitle>
          <Title>
            LymeMIND utilizes <strong>innovation, transparency and collaboration</strong> to accelerate the field of Lyme disease research.
              </Title>
        </TitleContainer>
        <TextContainer data-aos="fade-up" data-aos-duration="1000">
          <Paragraph>Through our integrated knowledge base, we aim to help Lyme disease researchers identify novel biomarkers and therapeutic target candidates. LymeMIND brings together leaders in Lyme disease research through collaborations, educational events, and an annual conference.</Paragraph>
          <TextLinkWrap>
            <TextLink to="/about">Lear more about LymeMIND</TextLink>
          </TextLinkWrap>
        </TextContainer>
      </Grid>
    </DefaultWrapper>
  </Wrapper>
)

export default AboutUs
