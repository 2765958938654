import React, { Fragment, useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import moment from "moment"
import { useMediaQuery } from "react-responsive"
import {
  Wrapper,
  HeroImg,
  HeroInfo,
  Title,
  Dates,
  ViewEventBtn,
  ResearchBtn,
  BtnWrapper,
  RegistrationTitle,
} from "./style"
import { PrimaryBtnExternal } from "../../../utils/GlobalComponents"
import Blob from "../../common/Blob/Blob"
import CalendarIcon from "../../../images/lyme-local-images/Calendar-3.png"
import PinIcon from "../../../images/lyme-local-images/Pin-2.png"
import RegisterModal from "../../RegisterModal/RegisterModal"

const Hero = ({ eventInfo }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
    if (!modalOpen) {
      setTimeout(() => {
        window.location.href = "#register"
      }, 200)
    }
  }

  useEffect(() => {
    if (window.location.hash === "#register") handleModalOpen()
  }, [])

  const data = useStaticQuery(graphql`
    query homeHero {
      file(relativePath: { eq: "home/hero-image.jpg" }) {
        publicURL
      }
    }
  `)
  return (
    <Fragment>
      <RegisterModal visible={modalOpen} onClose={() => handleModalOpen()} />

      <Wrapper>
        <Fragment>
          <Blob
            toTop={
              isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "15%" : "0%"
            }
            toRight={
              isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "15%" : "10%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "50vw"
                : isNotebookOrIpad
                ? "15vw"
                : "37.5vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
            delay={"400"}
          />

          <HeroImg
            bg={data.file.publicURL}
            data-aos="fade-up"
            data-aos-duration="1000"
            desktopImg={true}
          />

          <Blob
            toTop={isTabletOrMobileDevice ? "97%" : "79%"}
            toRight={isTabletOrMobileDevice ? "18%" : "67%"}
            customWidth={isTabletOrMobileDevice ? "16vw" : "16vw"}
            customHeight={"17vw"}
            delay={"700"}
            noMobile={true}
          />

          <HeroInfo>
            <Title data-aos="fade-up" data-aos-duration="1000" disable="mobile">
              Advancing <strong>Lyme disease research</strong> through an
              integrated network of researchers, patients, physicians, and
              sponsors.
            </Title>

            <HeroImg
              bg={data.file.publicURL}
              data-aos="fade-up"
              data-aos-duration="1000"
              desktopMobile={false}
            />

            <Fragment>
              {eventInfo ? (
                <Fragment>
                  <Dates data-aos="fade-up" data-aos-duration="1000">
                    {eventInfo.is_registration_open && (
                      <RegistrationTitle>
                        Registration is open (Free and open to the public)
                      </RegistrationTitle>
                    )}

                    <h5>{eventInfo.event_title}</h5>
                    <ul>
                      <li>
                        <img src={CalendarIcon} alt="Calendar" />
                        <span>{eventInfo.date}, {eventInfo.time}</span>
                      </li>
                      <li>
                        <img src={PinIcon} alt="Pin" />
                        <span>{eventInfo.location}</span>
                      </li>
                    </ul>
                  </Dates>
                  {eventInfo.is_registration_open && (
                    <BtnWrapper data-aos="fade-up" data-aos-duration="1000">
                      {/* <ViewEventBtn
                        as="button"
                        onClick={() => handleModalOpen(true)}
                        primary={"A"}
                      >
                        <span>Register Now</span>
                      </ViewEventBtn> */}
                      <PrimaryBtnExternal
                        // onClick={() => handleModalOpen(true)}
                        href="https://lyme-mind-2022.open-exchange.net/welcome"
                        target="_blank" rel="noopener noreferrer"
                        primary={"A"}
                      >
                        <span>Register Now</span>
                      </PrimaryBtnExternal>
                      {/*                 <ResearchBtn to="/research-projects" secondary={"A"}>
                <span>See the research</span>
              </ResearchBtn> */}
                    </BtnWrapper>
                  )}
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
            </Fragment>
          </HeroInfo>
        </Fragment>
      </Wrapper>
    </Fragment>
  )
}

export default Hero
