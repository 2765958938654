import React, { Fragment } from "react"
import { useMediaQuery } from "react-responsive"
import TeamMural from "./TeamMural/TeamMural"
import { Wrapper, TopTitle, Title, Text, TeamButton, GridWrap } from "./style"

import Blob from "../../common/Blob/Blob"

const Team = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })
  return (
    <Wrapper>
      <Blob
        toTop={"30px"}
        toRight={isNotebookOrIpad ? "20%" : "10vw"}
        customWidth={isTabletOrMobileDevice ? "50vw" : "16vw"}
        customHeight={isTabletOrMobileDevice ? "50vw" : "17vw"}
      />

      <Blob
        toTop={isNotebookOrIpad ? "40%" : "32%"}
        toRight={"43%"}
        customWidth={isTabletOrMobileDevice ? "0" : "4.5vw"}
        customHeight={"4.8vw"}
      />

      <Blob
        toTop={"70%"}
        toRight={"44%"}
        customWidth={isTabletOrMobileDevice ? "0" : "9vw"}
        customHeight={"10.3vw"}
      />

      <Blob
        toTop={isTabletOrMobileDevice ? "15px" : isNotebookOrIpad ? "20%" : "40%"}
        toLeft={
          isTabletOrMobileDevice ? "-20px" : isNotebookOrIpad ? "-50px" : "-230px"
        }
        customWidth={"24vw"}
        customHeight={"26vw"}
      />

      <GridWrap>
        <Fragment>
          <div>
            <TopTitle data-aos="fade-up" data-aos-duration="1000">
              Team
              </TopTitle>
            <Title data-aos="fade-up" data-aos-duration="1000">
              The LymeMIND Team
              </Title>
            <Text data-aos="fade-up" data-aos-duration="1000">
              The LymeMIND team, based at the Icahn School of Medicine at Mount Sinai, is made up of diverse experts in the areas of computational biology, epidemiology, software engineering, systems biology, and Lyme disease research.
              </Text>
            <TeamButton
              to="team"
              secondary={"A"}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span>Meet the Team</span>
            </TeamButton>
          </div>
          <TeamMural />
        </Fragment>
      </GridWrap>
    </Wrapper>
  )
}

export default Team
