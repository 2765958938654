import styled from "styled-components"

export const Wrapper = styled.div``

export const HeroImage = styled.div`
  display: grid;
  grid-template-columns: 160px 160px 160px 160px;
  grid-template-rows: 1fr;
  align-items: center;
  padding-right: 50px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(4, 90px);
    padding: 0 10px;
    margin-bottom: 30px;
  }
`

export const TinyImage = styled.div`
  background-color: ${props => (props.transparent ? "transparent" : "white")};
  display: inline-block;
  box-shadow: ${props =>
    props.transparent ? "none" : "1px 28px 60px rgba(0, 43, 52, 0.08)"};
  border-radius: 4px;

  width: 136px;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  @media (max-width: 991px) {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }

  .img {
    width: 100%;
    max-width: 83px;
    max-height: 80px;

    @media (max-width: 991px) {
      max-width: 50px;
      max-height: 48px;
    }
  }
`
