import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery } from "gatsby"
import { Wrapper, HeroImage, TinyImage } from "./style"

const TeamMural = () => {
  const data = useStaticQuery(graphql`{
  avi: file(relativePath: {eq: "home/avi-maayan.png"}) {
    childImageSharp {
      gatsbyImageData(width: 83, quality: 100, layout: CONSTRAINED)
    }
  }
  daniel: file(relativePath: {eq: "home/daniel-clarke.png"}) {
    childImageSharp {
      gatsbyImageData(width: 83, quality: 100, layout: CONSTRAINED)
    }
  }
  alex: file(relativePath: {eq: "home/alex-lachmann.png"}) {
    childImageSharp {
      gatsbyImageData(width: 83, quality: 100, layout: CONSTRAINED)
    }
  }
  erol: file(relativePath: {eq: "home/erol-evangelista.png"}) {
    childImageSharp {
      gatsbyImageData(width: 83, quality: 100, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <Wrapper>
      <HeroImage>
        <div>
          <TinyImage data-aos="zoom-in" data-aos-delay="100">
            <GatsbyImage
              image={data.avi.childImageSharp.gatsbyImageData}
              className="img"
              alt="LymeMIND Team" />
          </TinyImage>
          <TinyImage data-aos="zoom-in" data-aos-delay="100">
            <GatsbyImage
              image={data.alex.childImageSharp.gatsbyImageData}
              className="img"
              alt="LymeMIND Team" />
          </TinyImage>
        </div>
        <div>
          {/* <TinyImage data-aos="zoom-in" data-aos-delay="100">
            <GatsbyImage
              image={data.megan.childImageSharp.gatsbyImageData}
              className="img"
              alt="LymeMIND Team" />
          </TinyImage> */}
          <TinyImage data-aos="zoom-in" data-aos-delay="100">
            <GatsbyImage
              image={data.daniel.childImageSharp.gatsbyImageData}
              className="img"
              alt="LymeMIND Team" />
          </TinyImage> 
          <TinyImage data-aos="zoom-in" data-aos-delay="100">
            <GatsbyImage
              image={data.erol.childImageSharp.gatsbyImageData}
              className="img"
              alt="LymeMIND Team" />
          </TinyImage>
        </div>
      </HeroImage>
    </Wrapper>
  );
}

export default TeamMural
