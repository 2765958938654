import React from "react"
import { useStaticQuery } from "gatsby"
import Slider from "react-slick"
import {
  Wrapper,
  Card,
  TextLink,
  PressMedia,
  CardTitle,
  LinkWrap,
} from "./style"

const SliderPress = () => {
  const {
    allWordpressWpPress: { edges },
  } = useStaticQuery(graphql`
    query PressSlider {
      allWordpressWpPress {
        edges {
          node {
            acf {
              article_link
              press_article_title
              press_network_date
              press_network_title
            }
          }
        }
      }
    }
  `)

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "120px",
    slidesToShow: 4,
    autoplay: false,
    speed: 800,
    dots: false,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          infinite: true,
          centerPadding: "0",
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: false,
          centerPadding: "40px",
          dots: true,
        },
      },
    ],
  }

  return (
    <Wrapper className="slider-press">
      <Slider {...settings}>
        {edges.map(({ node }, i) => (
          <div key={node}>
            <Card
              data-aos="fade-up"
              data-aos-anchor=".slider-press"
              data-aos-offset="200"
            >
              <PressMedia>
                <h4>{node.acf.press_network_title}</h4>
                <h5>{node.acf.press_network_date}</h5>
              </PressMedia>
              <CardTitle>{node.acf.press_article_title}</CardTitle>
              <LinkWrap>
                <TextLink as="a" target="_blank" href={node.acf.article_link}>
                  View More
                </TextLink>
              </LinkWrap>
            </Card>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default SliderPress
