import styled from "styled-components"

import { SupTitle, MainH3 } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  background: url(${props => props.bgColor}) no-repeat center center / cover;

  padding-top: 40px;
  padding-bottom: 100px;
`
export const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 60px;
  margin-top: 20px;
`

export const TopTitle = styled(SupTitle)`
  color: white;
  &:after {
    display: none;
  }
`

export const Title = styled(MainH3)`
  background: none;
  color: white;
  font-weight: 500;
`
